import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logout, RootState } from "../store";
import {
  ApplicationSource,
  DebtType,
  HousingSituation,
  ICadastral,
  ICoApplicant,
  IDateYMD,
  IIncludeCreditCardApplication,
  ILoanApplicationCacheDto,
  ILoanApplicationSubmissionFormDto,
  IReportedProperty,
  ISubmissionFormUnsecuredDebt,
  NumberOfChildren,
  PartnerProductType,
  PropertyReportSource,
} from "../../services/nswag/clients/loanApplicationsClient";
import { setDebt, setPersonScore } from "./debtReducer";
import {
  handleUnitType,
  LoanDataToSubmissionFormUnsecuredDebt,
} from "../../utils/mappingDebt";
import { CustomerResidenceDto } from "../../services/nswag/clients/residenceClient";
import { parseBirthDate } from "../../utils/loanApplicationUtils";
import { CustomerWebDto } from "../../services/api/customer";
import {
  PayloadErrorHandling,
  PayloadWarningHandling,
} from "../classes/errorHandling";
import {
  InputFieldsNameCivilStatus,
  InputFieldsNameGjeldOgBolig,
} from "../../constants/inputFieldNamesSoknad";
import { addCoApplicant, removeCoApplicant } from "./stepsReducer";
import { errorPreFixNamingCoApplicant } from "../../pages/soknad/forbrukslan/medlantaker";
import { SoknadType } from "../../constants/soknadType";
import { saveLoanTypeFlow } from "../../utils/loanTypeHandler";

export interface StartOverPayload {
  paymentMark: number;
  soknadType: string;
}

export interface SetApplicationPayload {
  application: ILoanApplicationCacheDto;
  numberOfMarks: number;
}

export interface UpdateLoanApplication {
  name: string;
  value: any;
  objectName?: string;
  errorHandling?: string[];
}
export interface UpdateLoanApplicationCoApplicant {
  name: string;
  value: any;
  index: number;
  errorHandling?: string[];
}

export interface UpdateLoanApplicationDateYMD {
  name: string;
  value: any;
  objectName?: string;
  errorHandling?: string[];
}

export interface UpdateLoanApplicationReducer {
  name: string;
  value: any;
}

export interface LoanApplicationState {
  application: ILoanApplicationSubmissionFormDto;
  includeCreditCardApplication: IIncludeCreditCardApplication;
  page: string;
  existingApplication: boolean;
  fetchHousingLoading: boolean;
  housingFetched: boolean;
  errorHandling: any;
  warningHandling: any;
  loadingStoreCache: boolean;
}

export const initStateNewCoApplicant: ICoApplicant = {
  firstName: "",
  middleName: undefined,
  lastName: "",
  employmentSince: {
    day: 0,
    month: 1,
    year: 0,
  } as IDateYMD,
  childrenUnder18: undefined,
  education: undefined,
  employment: undefined,
  hasLivedInNorwayLast3Years: false,
  hasNorwegianCitizenship: false,
  housingSituation: undefined,
  maritalStatus: undefined,
  monthlyNetIncome: undefined,
  livesWithMainApplicant: undefined,
  paymentOnMortgageMonthly: undefined,
  remainingMortgageAmount: undefined,
  rentExpense: undefined,
  yearlyGrossIncome: undefined,
  hasPaymentRemarks: undefined,
  hasOtherIncome: undefined,
  otherIncome: undefined,
  receivesChildSupport: undefined,
  childSupportReceivedMonthly: undefined,
  email: "",
  employerName: "",
  hasMortgage: undefined,
  phoneNumber: "",
  socialNumber: "",
};

export const initialState: LoanApplicationState = {
  application: {
    product: PartnerProductType.ConsumerLoan,
    applicant: {
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      email: "",
      employment: undefined,
      employmentSince: {
        day: 0,
        month: 1,
        year: 0,
      },
      address: "",
      postalCode: "",
      city: "",
      nationality: "",
      birthDate: {
        day: 0,
        month: 0,
        year: 0,
      },
      childrenUnder18: undefined,
      maritalStatus: undefined,
      education: undefined,
      spouseGrossYearlyIncome: undefined,
      employerName: "",
      hasLivedInNorwayLast3Years: undefined,
    },
    newLoanAmount: 0,
    totalRefinancing: 0,
    purpose: undefined,
    existingDebt: [],
    housingSituation: undefined,
    reportedProperties: [],
    rentExpense: undefined,
    yearlyGrossIncome: undefined,
    monthlyNetIncome: undefined,
    hasRentIncome: undefined,
    rentIncome: undefined,
    hasOtherIncome: undefined,
    otherIncome: undefined,
    otherIncomeDescription: "",
    coApplicantIncluded: undefined,
    coApplicants: [],
    hasStudentLoan: undefined,
    totalStudentLoan: undefined,
    hasCarLoan: undefined,
    totalCarLoan: undefined,
    client: {
      appVersion: "",
      source: ApplicationSource.Webform,
    },
    childSupportRecievedMonthly: undefined,
    recievesChildSupport: undefined,
    hasOtherDebt: undefined,
    hasGuarantor: undefined,
    numberOfPaymentRemarks: undefined,
    leasingExpenseMonthly: undefined,
    mortgage: {
      hasMortgage: undefined,
      isSplit: undefined,
      monthlyPayment: undefined,
      remaining: undefined,
      ownershipPercentage: 100,
    },
    totalOtherDebt: undefined,
    guarantor: {
      socialNumber: "",
      email: "",
      phoneNumber: "",
    },
  },
  includeCreditCardApplication: {
    creditCardLimit: undefined,
  },
  existingApplication: false,
  fetchHousingLoading: false,
  housingFetched: false,
  page: "",
  errorHandling: {},
  warningHandling: {},
  loadingStoreCache: false,
};

const debtSlice = createSlice({
  name: "loanApplication",
  initialState,
  reducers: {
    prefillConsumerLoanForm: (
      state,
      action: PayloadAction<ILoanApplicationSubmissionFormDto>
    ) => {
      state.application = action.payload;
      state.application.product = PartnerProductType.ConsumerLoan;
    },
    setLoanApplication: (
      state,
      action: PayloadAction<SetApplicationPayload>
    ) => {
      const { payload } = action;
      const { existingDebt } = state.application;
      const { totalRefinancing } = state.application;

      state.application = { ...state.application, ...payload.application.form };
      state.application.existingDebt = existingDebt;
      state.application.totalRefinancing = totalRefinancing;
      state.application.numberOfPaymentRemarks = payload.numberOfMarks;

      state.existingApplication = true;
      state.page = payload.application.page ?? "";
    },
    updateLoanApplicationCreditCardApplication: (
      state,
      action: PayloadAction<UpdateLoanApplication>
    ) => {
      const { payload } = action;
      state.includeCreditCardApplication[payload.name] = payload.value;
    },
    updateLoanApplicationDateYMD: (
      state,
      action: PayloadAction<UpdateLoanApplicationDateYMD>
    ) => {
      const { payload } = action;
      if (payload.objectName) {
        state.application[payload.objectName] = {
          ...state.application[payload.objectName],
          [payload.name]: payload.value,
        };
      } else {
        state.application[payload.name] = payload.value;
      }
      state.errorHandling[payload.name] = false;
      if (payload?.errorHandling?.length > 0) {
        payload.errorHandling.forEach((error) => {
          state.errorHandling[error] = false;
        });
      }
    },
    updateLoanApplication: (
      state,
      action: PayloadAction<UpdateLoanApplication>
    ) => {
      const { payload } = action;

      if (payload.objectName) {
        state.application[payload.objectName] = {
          ...state.application[payload.objectName],
          [payload.name]: payload.value,
        };
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        state.application[payload.name] = payload.value;
      }
      state.errorHandling[payload.name] = false;
      state.warningHandling[payload.name] = false;

      if (payload?.errorHandling?.length > 0) {
        payload.errorHandling.forEach((error) => {
          state.errorHandling[error] = false;
        });
      }

      if (payload.name === InputFieldsNameCivilStatus.childrenUnder18) {
        if (payload.value === NumberOfChildren.None) {
          state.application.recievesChildSupport = false;
        }
      }

      if (payload.name === InputFieldsNameGjeldOgBolig.isSplit) {
        state.application.mortgage.ownershipPercentage = state.application
          .mortgage.isSplit
          ? 50
          : 100;
      }
    },
    updateLoanApplicationCoApplicant: (
      state,
      action: PayloadAction<UpdateLoanApplicationCoApplicant>
    ) => {
      const { payload } = action;

      state.application.coApplicants = state.application.coApplicants.map(
        (coApplicant, index) =>
          index === payload.index
            ? { ...coApplicant, [payload.name]: payload.value }
            : coApplicant
      );

      state.errorHandling[errorPreFixNamingCoApplicant + payload.name] = false;
      state.warningHandling[errorPreFixNamingCoApplicant + payload.name] =
        false;

      if (payload?.errorHandling?.length > 0) {
        payload.errorHandling.forEach((error) => {
          state.errorHandling[errorPreFixNamingCoApplicant + error] = false;
        });
      }
    },
    updateLoanApplicationReducer: (
      state,
      action: PayloadAction<UpdateLoanApplicationReducer>
    ) => {
      const { payload } = action;
      state[payload.name] = payload.value;
      state.errorHandling[payload.name] = false;
    },
    startOverApplication: (state, action: PayloadAction<StartOverPayload>) => {
      const payload = action?.payload;
      const product =
        payload?.soknadType === SoknadType.Restartloan
          ? PartnerProductType.RestartLoan
          : PartnerProductType.ConsumerLoan;

      state.application = {
        ...initialState.application,
        ...{
          applicant: {
            ...initialState.application.applicant,
            ...{
              firstName: state.application.applicant.firstName,
              lastName: state.application.applicant.lastName,
              middleName: state.application.applicant.middleName,
              phoneNumber: state.application.applicant.phoneNumber,
              email: state.application.applicant.email,
              address: state.application.applicant.address,
              postalCode: state.application.applicant.postalCode,
              city: state.application.applicant.city,
              nationality: state.application.applicant.nationality,
              birthDate: state.application.applicant.birthDate,
            },
          },
          product,
          existingDebt: state.application.existingDebt,
          totalRefinancing: state.application.totalRefinancing,
          numberOfPaymentRemarks: payload?.paymentMark,
        },
      };
      state.includeCreditCardApplication =
        initialState.includeCreditCardApplication;
      state.existingApplication = false;
      state.page = "";
    },
    removingHousing: (state, action: PayloadAction<string>) => {
      state.application.reportedProperties =
        state.application.reportedProperties.filter((housing) => {
          return housing.address !== action.payload;
        });
    },
    updateReportedProperty: (
      state,
      action: PayloadAction<{ index: number; updated: IReportedProperty }>
    ) => {
      state.application.reportedProperties[action.payload.index] =
        action.payload.updated;
    },
    addReportedProperty: (state, action: PayloadAction<IReportedProperty>) => {
      state.application.reportedProperties.push(action.payload);
    },
    setRefinanceDebt: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      let totalRefinance = 0;
      state.application.existingDebt = state.application.existingDebt.map(
        (loan) => {
          if (loan.id === payload) {
            const newRefinanceState = !loan.refinance;
            if (newRefinanceState) {
              totalRefinance += loan.remaining + (loan?.borrowed ?? 0.0);
            }
            return {
              ...loan,
              refinance: newRefinanceState,
            };
          }

          if (loan.refinance) {
            totalRefinance += loan.remaining + (loan?.borrowed ?? 0.0);
          }
          return loan;
        }
      );

      state.application.totalRefinancing = totalRefinance;
    },
    setHousings: (state, action: PayloadAction<CustomerResidenceDto[]>) => {
      state.application.reportedProperties = action.payload.map((housing) => {
        return {
          source: housing.confirmedOwnership
            ? PropertyReportSource.PropertyRegistry
            : PropertyReportSource.SelfReported,
          address: housing.address,
          type: handleUnitType(housing.unitType),
          floorCode: housing.floorCode,
          areaSize: housing.size,
          estimatedValue: housing.estimate?.estimatedValue,
          municipalityName: housing.municipalityName,
          postalCode: housing.postalCode,
          cadastral: {
            cadastralUnitNumber: housing.matrikkel?.municipalityNumber,
            leaseNumber: housing.matrikkel?.leaseNumber,
          } as ICadastral,
          ownershipPercentage: housing.numberOfOwnersOnLevel === 0 ? 100 : null,
        } as IReportedProperty;
      });
      state.fetchHousingLoading = false;
      state.housingFetched = true;
    },
    updateApplicantFromCustomer: (
      state,
      action: PayloadAction<CustomerWebDto>
    ) => {
      const { payload } = action;
      state.application.applicant.nationality = payload.nationality;
      state.application.applicant.firstName = payload.firstName;
      state.application.applicant.lastName = payload.lastName;
      state.application.applicant.middleName = payload.middleName;
      state.application.applicant.email = payload.email;
      state.application.applicant.phoneNumber = payload.phoneNumber;
      state.application.applicant.address = payload.address;
      state.application.applicant.postalCode = payload.postalCode;
      state.application.applicant.city = payload.city;
      const parsedDate = parseBirthDate(payload.birthDate);
      state.application.applicant.birthDate.year = parsedDate.year;
      state.application.applicant.birthDate.month = parsedDate.month;
      state.application.applicant.birthDate.day = parsedDate.day;
    },
    updateErrorHandling: (
      state,
      action: PayloadAction<PayloadErrorHandling[]>
    ) => {
      const { payload } = action;
      payload.forEach((error) => {
        state.errorHandling[error.name] = error.value;
      });
    },
    updateWarningHandling: (
      state,
      action: PayloadAction<PayloadWarningHandling[]>
    ) => {
      const { payload } = action;
      payload.forEach((error) => {
        state.warningHandling[error.name] = error.value;
      });
    },
    refreshWarningHandlingStatus: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      payload.forEach((name) => {
        state.warningHandling[name] = false;
      });
    },
    refreshErrorHandlingStatus: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      payload.forEach((name) => {
        state.errorHandling[name] = false;
      });
    },
    removeError: (state, action: PayloadAction<PayloadErrorHandling>) => {
      state.errorHandling[action.payload.name] = false;
    },
    changeProduct: (state, action: PayloadAction<PartnerProductType>) => {
      state.application.product = action.payload;
      if (action.payload === PartnerProductType.ConsumerLoan) {
        saveLoanTypeFlow({ loanType: SoknadType.ConsumerLoan });
      }
      if (action.payload === PartnerProductType.RestartLoan) {
        saveLoanTypeFlow({ loanType: SoknadType.Restartloan });
      }
    },
    setLoadingStoreCache: (state, action: PayloadAction<boolean>) => {
      state.loadingStoreCache = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState; // Reset to initial state
    });
    builder.addCase(addCoApplicant, (state) => {
      if (state.application.coApplicants.length > 0) {
        return state;
      }
      const newApplication = {
        ...state.application,
        coApplicants: [],
      } as ILoanApplicationSubmissionFormDto;

      newApplication.coApplicants.push(initStateNewCoApplicant);

      return {
        ...state,
        application: newApplication,
      };
    });
    builder.addCase(removeCoApplicant, (state) => {
      const newApplication = {
        ...state.application,
        coApplicants: [],
      };
      return {
        ...state,
        application: newApplication,
      };
    });
    builder.addCase(setDebt, (state, action) => {
      const newLoans = action.payload.loans.map((loan, index) => {
        return LoanDataToSubmissionFormUnsecuredDebt(loan, index);
      }) as ISubmissionFormUnsecuredDebt[];

      const newApplication = {
        ...state.application,
        existingDebt: newLoans,
        totalRefinancing: newLoans.reduce((previousValue, currentValue) => {
          if (currentValue.type === DebtType.ConsumerLoan) {
            return previousValue + currentValue.remaining;
          }
          return previousValue + currentValue.borrowed;
        }, 0),
      } as ILoanApplicationSubmissionFormDto;
      return {
        ...state,
        application: newApplication,
      };
    });
    builder.addCase(setPersonScore, (state, action) => {
      state.application.numberOfPaymentRemarks =
        action.payload.paymentMarkTotal;
    });
  },
});
export const getIncludeCreditCardApplication = (state: RootState) =>
  state.loanApplicationReducer.includeCreditCardApplication;

export const getLoanApplication = (state: RootState) =>
  state.loanApplicationReducer;

export const getLoanApplicationForm = (state: RootState) =>
  state.loanApplicationReducer.application;

export const getLoanApplicationFormApplicant = (state: RootState) =>
  state.loanApplicationReducer.application.applicant;

export const isGuarantorRequired = (state: RootState) => {
  const hasSecurityInProperty =
    state.loanApplicationReducer.application.reportedProperties.length > 0;
  const rents =
    state.loanApplicationReducer.application.housingSituation ===
    HousingSituation.Rents;

  return (
    (!hasSecurityInProperty && rents) ||
    state.loanApplicationReducer.application.hasGuarantor !== null
  );
};
export const isAnyValidationError = (state: RootState) =>
  Object.values(state.loanApplicationReducer.errorHandling).some(
    (value) => value === true
  );

export const {
  updateLoanApplicationReducer,
  setLoanApplication,
  updateLoanApplicationCreditCardApplication,
  updateLoanApplicationCoApplicant,
  updateLoanApplication,
  updateLoanApplicationDateYMD,
  startOverApplication,
  removingHousing,
  setRefinanceDebt,
  setHousings,
  updateReportedProperty,
  addReportedProperty,
  updateApplicantFromCustomer,
  updateErrorHandling,
  refreshWarningHandlingStatus,
  refreshErrorHandlingStatus,
  updateWarningHandling,
  removeError,
  changeProduct,
  prefillConsumerLoanForm,
  setLoadingStoreCache,
} = debtSlice.actions;

export default debtSlice.reducer;
