import { AxiosPromise } from "axios";
import axios from "../axiosInstance";
import {
  FacebookFbclid,
  GoogleGlid,
  ReadPeak,
  SnapChatClickId,
  TikTokClickId,
  TrackingValues,
} from "../../utils/tracking";

export enum TrackingEvent {
  LoanStarted = 0,
  LoanRequestSent = 1,
  LoanPaidOut = 2,
}
export const api_sendClickEvent = (
  eventName: TrackingEvent,
  trackings: TrackingValues
): AxiosPromise => {
  return axios({
    method: "GET",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-message-web/tracking/event?eventType=${eventName}`,
    headers: {
      [ReadPeak]: trackings?.rpcid,
      [TikTokClickId]: trackings?.ttclid,
      [SnapChatClickId]: trackings?.ScCid,
      [GoogleGlid]: trackings?.gclid,
      [FacebookFbclid]: trackings?.fbclid,
    },
  });
};
