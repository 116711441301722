import { AxiosPromise } from "axios";
import axios from "../axiosInstance";

export interface CustomerWebDto {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  nationality: string;
  birthDate: string;
  maritalStatus: string;
}

export const apiGetCustomer = (): AxiosPromise<CustomerWebDto> => {
  return axios({
    method: "GET",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-customer-web/web/customer`,
  });
};

export const apiFetchValidPostalNumber = (
  zipCode: string
): AxiosPromise<boolean> => {
  return axios({
    method: "GET",
    url: `${process.env.API_URL}/s-customer-web/web/customer/valid-zipcode?zipcode=${zipCode}`,
  });
};
