export const InputFieldsNameCivilStatus = {
  maritalStatus: "maritalStatus",
  education: "education",
  childrenUnder18: "childrenUnder18",
  recievesChildSupport: "recievesChildSupport",
  childSupportRecievedMonthly: "childSupportRecievedMonthly",
  hasLivedInNorwayLast3Years: "hasLivedInNorwayLast3Years",
  coApplicantIncluded: "coApplicantIncluded",
  spouseGrossYearlyIncome: "spouseGrossYearlyIncome",
};

export const InputFieldsNameBelop = {
  newLoanAmount: "newLoanAmount",
  purpose: "purpose",
  totalRefinancing: "totalRefinancing",
};

export const InputFieldsNameBolig = {
  housingSituation: "housingSituation",
  rentExpense: "rentExpense",
  hasRentIncome: "hasRentIncome",
  rentIncome: "rentIncome",
  reportedProperty: "reportedProperty",
  hasGuarantor: "hasGuarantor",
};

export const InputFieldsNameAddProperty = {
  address: "address",
  postalCode: "postalCode",
  type: "type",
  estimatedValue: "estimatedValue",
  ownershipPercentage: "ownershipPercentage",
  electronicEvaluationRefNumber: "electronicEvaluationRefNumber",
};

export const InputFieldsNameOppsummering = {
  newLoanAmount: "newLoanAmount",
};

export const InputFieldsNameKredittkort = {
  creditCardLimit: "creditCardLimit",
};

export const InputFieldsNameGjeldOgBolig = {
  hasMortgage: "hasMortgage",
  isSplit: "isSplit",
  monthlyPayment: "monthlyPayment",
  remaining: "remaining",
  ownershipPercentage: "ownershipPercentage",
  hasStudentLoan: "hasStudentLoan",
  totalStudentLoan: "totalStudentLoan",
  totalCarLoan: "totalCarLoan",
  leasingExpenseMonthly: "leasingExpenseMonthly",
  hasOtherDebt: "hasOtherDebt",
  hasCarLoan: "hasCarLoan",
  totalOtherDebt: "totalOtherDebt",
};

export const InputFieldsNameInntekt = {
  yearlyGrossIncome: "yearlyGrossIncome",
  monthlyNetIncome: "monthlyNetIncome",
  employment: "employment",
  employmentSince: "employmentSince",
  employerName: "employerName",
  hasOtherIncome: "hasOtherIncome",
  otherIncome: "otherIncome",
  otherIncomeDescription: "otherIncomeDescription",
  hasRentIncome: "hasRentIncome",
  rentIncome: "rentIncome",
  employmentSinceMonth: "employmentSinceMonth",
};

export const InputFieldsCoApplicant = {
  socialNumber: "socialNumber",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  phoneNumber: "phoneNumber",
  hasNorwegianCitizenship: "hasNorwegianCitizenship",
  maritalStatus: "maritalStatus",
  education: "education",
  yearlyGrossIncome: "yearlyGrossIncome",
  monthlyNetIncome: "monthlyNetIncome",
  employment: "employment",
  employmentSince: "employmentSince",
  livesWithMainApplicant: "livesWithMainApplicant",
  employerName: "employerName",
  housingSituation: "housingSituation",
  rentExpense: "rentExpense",
  paymentOnMortgageMonthly: "paymentOnMortgageMonthly",
  childrenUnder18: "childrenUnder18",
  remainingMortgageAmount: "remainingMortgageAmount",
  employmentSinceMonth: "employmentSinceMonth",
  hasMortgage: "hasMortgage",
  hasPaymentRemarks: "hasPaymentRemarks",
  hasOtherIncome: "hasOtherIncome",
  otherIncome: "otherIncome",
  receivesChildSupport: "receivesChildSupport",
  childSupportReceivedMonthly: "childSupportReceivedMonthly",
};

export const InputFieldsNamesGuarantor = {
  socialNumber: "socialNumber",
  email: "email",
  phoneNumber: "phoneNumber",
};

export const InputFieldsNameApplicant = {
  email: "email",
  phoneNumber: "phoneNumber",
};
