import { Loan, LoanData } from "../services/api/debt";
import {
  DebtType,
  PropertyType,
} from "../services/nswag/clients/loanApplicationsClient";

const divideFromOreToKr = 100;

export const handleDebtType = (loan: LoanData) => {
  if (loan.isChargeCard) {
    return DebtType.ChargeCard;
  }
  if (loan.isRepaymentLoan) {
    return DebtType.ConsumerLoan;
  }
  if (loan.isCreditCard) {
    return DebtType.CreditCard;
  }
  return DebtType.CommonDebt;
};

export const handleBorrowedAmount = (_d: LoanData): number => {
  return (
    ((_d?.nonInterestBearingBalance ?? 0) + (_d?.interestBearingBalance ?? 0)) /
    divideFromOreToKr
  );
};

export const getDebtItemCreditLimit = (_d: LoanData): number => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (_d?.isRepaymentLoan) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return _d?.originalBalance / divideFromOreToKr;
  }
  return (_d?.creditLimit ?? 0) / divideFromOreToKr;
};

export const getDebtItemEffectiveInterestRate = (_d: LoanData): number => {
  try {
    const period = _d?.installmentChargePeriod === "MONTHLY" ? 12 : 1;
    const charges = _d?.installmentCharges / divideFromOreToKr;
    const balance =
      handleBorrowedAmount(_d) > 0 ? period / handleBorrowedAmount(_d) : 0;

    return +(
      ((1 + _d?.nominalInterestRate / 10000 / 12) ** 12 -
        1 +
        charges * balance) *
      divideFromOreToKr
    ).toFixed(2);
  } catch {
    return _d?.nominalInterestRate / divideFromOreToKr;
  }
};

export const getTotalFee = (_d: LoanData): number => {
  return _d?.installmentCharges / divideFromOreToKr;
};

export const debtNameFormat = (type?: DebtType) => {
  if (!type) {
    return "";
  }
  switch (type) {
    case DebtType.ConsumerLoan:
      return "Forbrukslån";
    case DebtType.CreditCard:
      return "Kredittkort";
    case DebtType.CommonDebt:
      return "Vanlig gjeld";
    case DebtType.ChargeCard:
      return "Faktureringskort";
    default:
      return "Ukjent";
  }
};
export const handleUnitType = (type?: string) => {
  switch (type) {
    case "enebolig i rekke":
      return PropertyType.House;
    case "house":
      return PropertyType.House;
    case "apartment":
      return PropertyType.Apartment;
    case "holidayhome":
      return PropertyType.HolidayHome;
    case "semidetatchedhouse":
      return PropertyType.SemiDetachedHouse;
    case "tomannsbolig, vertikaldelt":
      return PropertyType.SemiDetachedHouseVertical;
    case "serialhouse":
      return PropertyType.SerialHouse;
    default:
  }
};

export const LoanDataToSubmissionFormUnsecuredDebt = (
  loan: Loan,
  id: number
) => {
  const type = handleDebtType(loan.loan);

  let monthlyPaymentDay = 0;
  if (loan.loan.terms) {
    monthlyPaymentDay =
      loan.loan.installmentChargePeriod === "YEARLY"
        ? loan?.loan.terms ?? 0 / 12
        : loan.loan.terms;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _id = loan.loan.id === 0 ? id : loan.loan.id;

  return {
    bankName: loan.creditorName,
    limit: getDebtItemCreditLimit(loan.loan),
    borrowed: handleBorrowedAmount(loan.loan), // Forbrukslån lånesum, kredittkort utestående, KUN KREDITTKORT
    nominalInterestRate: loan.loan.nominalInterestRate / divideFromOreToKr,
    effectiveInterestRate: getDebtItemEffectiveInterestRate(loan.loan),
    monthsRemainingOnDownPayment: monthlyPaymentDay, // Antall måneder igjen på nedbetaling
    totalMonthlyFee: getTotalFee(loan.loan),
    refinance: true,
    remaining: loan.loan.balance / divideFromOreToKr, // Forbruksån / KUN FORBRUKSLÅN
    id: _id,
    type,
  };
};
