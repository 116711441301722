import { navigate } from "gatsby";
import { AppThunk } from "../store";
import {
  GetLoanApplicationClient,
  GetResidenceClient,
} from "../../services/axiosInstance";
import {
  prefillConsumerLoanForm,
  setHousings,
  setLoadingStoreCache,
  updateApplicantFromCustomer,
  updateLoanApplication,
  updateLoanApplicationReducer,
} from "../reducers/loanApplicationReducer";
import { apiGetCustomer } from "../../services/api/customer";
import {
  LoanApplicationSubmissionFormDto,
  PartnerProductType,
} from "../../services/nswag/clients/loanApplicationsClient";
import { NavigationNames } from "../../constants/navigationNames";
import { saveLoanTypeFlow, switchLoanType } from "../../utils/loanTypeHandler";
import { LoanTypeToSoknadType } from "../../utils/navigationLoanTypeUtils";
import { apiInitiateApplyForLoan } from "../../services/api/auth";
import { setCustomer } from "../reducers/customerReducer";
import { ConvertPagePathToType } from "../../utils/loanApplicationPageUtils";
import { apiFetchDebt } from "../../services/api/debt";
import { setDebt } from "../reducers/debtReducer";
import { InputFieldsNameCivilStatus } from "../../constants/inputFieldNamesSoknad";

const loanApplicationClient = GetLoanApplicationClient();
const residenceClient = GetResidenceClient();

export const actionSaveCachedLoanApplication =
  (callback?: any, nextPage?: string): AppThunk | any =>
  async (dispatch: any, getState: any) => {
    const loanApplicationState = getState().loanApplicationReducer;

    let page = "";
    if (typeof window !== "undefined") {
      page = window.location.pathname;
    }
    dispatch(setLoadingStoreCache(true));
    loanApplicationClient
      .saveToCache({
        data: {
          form: loanApplicationState.application,
          page: ConvertPagePathToType(
            nextPage ?? page,
            loanApplicationState.application.product
          ),
        },
      })
      .then(() => {
        dispatch(setLoadingStoreCache(false));

        if (callback) {
          callback();
        }
      })
      .catch(() => {
        dispatch(setLoadingStoreCache(false));
      });
  };

export const actionFetchHousing =
  (): AppThunk | any => async (dispatch: any) => {
    dispatch(
      updateLoanApplicationReducer({ name: "fetchHousingLoading", value: true })
    );
    residenceClient
      .residence("")
      .then((resp) => {
        dispatch(setHousings(resp));
      })
      .catch(() => {
        updateLoanApplicationReducer({
          name: "fetchHousingLoading",
          value: false,
        });
      });
  };

export const actionFetchCustomer =
  (): AppThunk | any => async (dispatch: any) => {
    apiGetCustomer()
      .then((resp) => {
        dispatch(updateApplicantFromCustomer(resp.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const actionSwitchProductType =
  (productType: PartnerProductType): AppThunk | any =>
  async (dispatch: any) => {
    dispatch(
      updateLoanApplication({
        name: "product",
        value: productType,
      })
    );
    switchLoanType(LoanTypeToSoknadType(productType));
    if (productType === PartnerProductType.RestartLoan) {
      navigate(NavigationNames.OMSTARTSLAN.BASE);
      return;
    }
    if (productType === PartnerProductType.ConsumerLoan) {
      navigate(NavigationNames.SOKNAD.BELOP);
    }
  };

export const actionStartBankId =
  (
    email: string,
    phoneNumber: string,
    type: string,
    callback?: any
  ): AppThunk | any =>
  async (dispatch: any) => {
    if (typeof window !== "undefined") {
      const baseUrl = `${window.location.origin}/soknad`;
      apiInitiateApplyForLoan({
        email,
        phoneNumber: phoneNumber.replace(/\s+/g, ""),
        callbackUri: `${baseUrl}/bankid-callback`,
        type,
      })
        .then((resp) => {
          dispatch(
            setCustomer({
              email,
              phoneNumber,
              id: 0,
              ssn: "",
              name: "",
            })
          );
          if (callback) {
            callback(false);
          }
          navigate(resp.data.redirectUri);
        })
        .catch(() => {
          if (callback) {
            callback(false);
          }
          navigate(NavigationNames.SOKNAD.ERROR);
        });
    }
  };

export const applyAgainForConsumerLoan =
  (withCoApplicant?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    apiGetCustomer()
      .then((resp) => {
        dispatch(updateApplicantFromCustomer(resp.data));
      })
      .catch(() => {
        navigate(NavigationNames.SOKNAD.ERROR);
      });

    if (!state.debtReducer.debtIsFetched) {
      apiFetchDebt()
        .then((response) => {
          dispatch(setDebt(response.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (withCoApplicant) {
      dispatch(
        updateLoanApplication({
          name: InputFieldsNameCivilStatus.coApplicantIncluded,
          value: true,
        })
      );
    }
    saveLoanTypeFlow({ loanType: PartnerProductType.ConsumerLoan });
    navigate(NavigationNames.SOKNAD.BELOP);
  };

export const applyAgainForConsumerLoanWithPrefill =
  (prefill: LoanApplicationSubmissionFormDto): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    dispatch(prefillConsumerLoanForm(prefill));

    apiGetCustomer()
      .then((resp) => {
        dispatch(updateApplicantFromCustomer(resp.data));
      })
      .catch(() => {
        navigate(NavigationNames.SOKNAD.ERROR);
      });

    if (!state.debtReducer.debtIsFetched) {
      apiFetchDebt()
        .then((response) => {
          dispatch(setDebt(response.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    saveLoanTypeFlow({ loanType: PartnerProductType.ConsumerLoan });
    navigate(NavigationNames.SOKNAD.BELOP);
  };
