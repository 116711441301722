import { PayloadErrorHandling } from "../redux/classes/errorHandling";
import {
  InputFieldsCoApplicant,
  InputFieldsNameAddProperty,
  InputFieldsNameBolig,
  InputFieldsNameCivilStatus,
  InputFieldsNameGjeldOgBolig,
  InputFieldsNameInntekt,
  InputFieldsNamesGuarantor,
} from "../constants/inputFieldNamesSoknad";
import {
  IApplicant,
  HasCarLoan,
  HousingSituation,
  ICoApplicant,
  ILoanApplicationSubmissionFormDto,
  IReportedProperty,
  NumberOfChildren,
  IGuarantor,
  MaritalStatusType,
} from "../services/nswag/clients/loanApplicationsClient";
import { errorPreFixReportedProperty } from "../components/soknad/stepper/AddHouseModal";
import { errorPreFixNamingCoApplicant } from "../pages/soknad/forbrukslan/medlantaker";
import { showEmploymentName, showMaritalIncome } from "./loanApplicationUtils";
import {
  validateNorwegianPhoneNumber,
  validateNorwegianSSN,
} from "./stringValidation";
import { getYears, isIncludedMonth } from "./dateUtils";
import { emailValidation } from "./warningValidationUtils";

const years = getYears;

export const BoligValidationError = (
  application: ILoanApplicationSubmissionFormDto
) => {
  const errors = [] as PayloadErrorHandling[];
  if (!application.housingSituation) {
    errors.push({ name: InputFieldsNameBolig.housingSituation, value: true });
  }

  if (application.housingSituation === HousingSituation.Rents) {
    if (!application.rentExpense) {
      errors.push({ name: InputFieldsNameBolig.rentExpense, value: true });
    }
    if (
      application.hasRentIncome === undefined ||
      application.hasRentIncome === null
    ) {
      errors.push({ name: InputFieldsNameBolig.hasRentIncome, value: true });
    }
    if (application.hasRentIncome) {
      if (!application.rentIncome) {
        errors.push({ name: InputFieldsNameBolig.rentIncome, value: true });
      }
    }
  }
  if (application.housingSituation === HousingSituation.OwnsHouse) {
    if (
      !application.reportedProperties ||
      application.reportedProperties.length === 0
    ) {
      errors.push({ name: InputFieldsNameBolig.reportedProperty, value: true });
    }
  }
  return errors;
};

export const GjeldValidationError = (
  application: ILoanApplicationSubmissionFormDto
) => {
  const errors = [] as PayloadErrorHandling[];
  if (
    application.mortgage.hasMortgage === undefined ||
    application.mortgage.hasMortgage === null
  ) {
    errors.push({
      name: InputFieldsNameGjeldOgBolig.hasMortgage,
      value: true,
    });
  }

  if (application.mortgage.hasMortgage) {
    if (
      application.mortgage.isSplit === undefined ||
      application.mortgage.isSplit === null
    ) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.isSplit,
        value: true,
      });
    }
    if (application.mortgage.isSplit) {
      if (!application.mortgage.ownershipPercentage) {
        errors.push({
          name: InputFieldsNameGjeldOgBolig.ownershipPercentage,
          value: true,
        });
      }
    }
    if (!application.mortgage.remaining) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.remaining,
        value: true,
      });
    }
    if (!application.mortgage.monthlyPayment) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.monthlyPayment,
        value: true,
      });
    }
  }

  if (
    application.hasStudentLoan === undefined ||
    application.hasStudentLoan === null
  ) {
    errors.push({
      name: InputFieldsNameGjeldOgBolig.hasStudentLoan,
      value: true,
    });
  }

  if (application.hasStudentLoan) {
    if (!application.totalStudentLoan) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.totalStudentLoan,
        value: true,
      });
    }
  }

  if (application.hasCarLoan === undefined || application.hasCarLoan === null) {
    errors.push({
      name: InputFieldsNameGjeldOgBolig.hasCarLoan,
      value: true,
    });
  }

  if (application.hasCarLoan === HasCarLoan.Yes) {
    if (!application.totalCarLoan) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.totalCarLoan,
        value: true,
      });
    }
  }

  if (application.hasCarLoan === HasCarLoan.Leasing) {
    if (!application.leasingExpenseMonthly) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.leasingExpenseMonthly,
        value: true,
      });
    }
  }

  /*
  if (
    application.hasOtherDebt === undefined ||
    application.hasOtherDebt === null
  ) {
    errors.push({
      name: InputFieldsNameGjeldOgBolig.hasOtherDebt,
      value: true,
    });
  }
  if (application.hasOtherDebt) {
    if (!application.totalOtherDebt) {
      errors.push({
        name: InputFieldsNameGjeldOgBolig.totalOtherDebt,
        value: true,
      });
    }
  }
    */
  return errors;
};

export const InntektValidationError = (
  application: ILoanApplicationSubmissionFormDto
) => {
  const errors = [] as PayloadErrorHandling[];
  if (!application.yearlyGrossIncome) {
    errors.push({
      name: InputFieldsNameInntekt.yearlyGrossIncome,
      value: true,
    });
  }

  if (!application.monthlyNetIncome) {
    errors.push({
      name: InputFieldsNameInntekt.monthlyNetIncome,
      value: true,
    });
  }

  if (!application.applicant.employment) {
    errors.push({
      name: InputFieldsNameInntekt.employment,
      value: true,
    });
  }

  if (
    application.applicant.employment &&
    showEmploymentName(application.applicant.employment)
  ) {
    if (!application.applicant.employerName) {
      errors.push({
        name: InputFieldsNameInntekt.employerName,
        value: true,
      });
    }
    if (!application.applicant.employmentSince.year) {
      errors.push({
        name: InputFieldsNameInntekt.employmentSince,
        value: true,
      });
    }

    if (application.applicant.employmentSince.year) {
      if (
        [years[0], years[1]].includes(
          application.applicant?.employmentSince.year ?? 0
        )
      ) {
        if (!application.applicant.employmentSince.month) {
          errors.push({
            name: InputFieldsNameInntekt.employmentSinceMonth,
            value: true,
          });
        }
      }
    }
  }

  if (
    application.hasOtherIncome === undefined ||
    application.hasOtherIncome === null
  ) {
    errors.push({
      name: InputFieldsNameInntekt.hasOtherIncome,
      value: true,
    });
  }

  if (application.hasOtherIncome) {
    if (!application.otherIncome) {
      errors.push({
        name: InputFieldsNameInntekt.otherIncome,
        value: true,
      });
    }

    if (!application.otherIncomeDescription) {
      errors.push({
        name: InputFieldsNameInntekt.otherIncomeDescription,
        value: true,
      });
    }
  }

  if (
    application.hasRentIncome === undefined ||
    application.hasRentIncome === null
  ) {
    errors.push({
      name: InputFieldsNameInntekt.hasRentIncome,
      value: true,
    });
  }

  if (application.hasRentIncome) {
    if (!application.rentIncome) {
      errors.push({
        name: InputFieldsNameInntekt.rentIncome,
        value: true,
      });
    }
  }

  return errors;
};

export const OmstartslanInntektValidationError = (
  application: ILoanApplicationSubmissionFormDto
) => {
  const errors = [] as PayloadErrorHandling[];
  if (!application.yearlyGrossIncome) {
    errors.push({
      name: InputFieldsNameInntekt.yearlyGrossIncome,
      value: true,
    });
  }

  if (!application.applicant.employment) {
    errors.push({
      name: InputFieldsNameInntekt.employment,
      value: true,
    });
  }

  if (
    application.applicant.employment &&
    showEmploymentName(application.applicant.employment)
  ) {
    if (!application.applicant.employerName) {
      errors.push({
        name: InputFieldsNameInntekt.employerName,
        value: true,
      });
    }
    if (!application.applicant.employmentSince.year) {
      errors.push({
        name: InputFieldsNameInntekt.employmentSince,
        value: true,
      });
    }

    if (application.applicant.employmentSince.year) {
      if (
        [years[0], years[1]].includes(
          application.applicant?.employmentSince.year ?? 0
        )
      ) {
        if (!application.applicant.employmentSince.month) {
          errors.push({
            name: InputFieldsNameInntekt.employmentSinceMonth,
            value: true,
          });
        }
      }
    }
  }

  if (
    application.hasOtherIncome === undefined ||
    application.hasOtherIncome === null
  ) {
    errors.push({
      name: InputFieldsNameInntekt.hasOtherIncome,
      value: true,
    });
  }

  if (application.hasOtherIncome) {
    if (!application.otherIncome) {
      errors.push({
        name: InputFieldsNameInntekt.otherIncome,
        value: true,
      });
    }

    if (!application.otherIncomeDescription) {
      errors.push({
        name: InputFieldsNameInntekt.otherIncomeDescription,
        value: true,
      });
    }
  }

  if (
    application.hasRentIncome === undefined ||
    application.hasRentIncome === null
  ) {
    errors.push({
      name: InputFieldsNameInntekt.hasRentIncome,
      value: true,
    });
  }

  if (application.hasRentIncome) {
    if (!application.rentIncome) {
      errors.push({
        name: InputFieldsNameInntekt.rentIncome,
        value: true,
      });
    }
  }

  return errors;
};

export const SivilstatusValidationError = (
  application: ILoanApplicationSubmissionFormDto
) => {
  const errors = [] as PayloadErrorHandling[];

  if (
    application.applicant.hasLivedInNorwayLast3Years === undefined ||
    application.applicant.hasLivedInNorwayLast3Years === null
  ) {
    errors.push({
      name: InputFieldsNameCivilStatus.hasLivedInNorwayLast3Years,
      value: true,
    });
  }

  if (!application.applicant.maritalStatus) {
    errors.push({
      name: InputFieldsNameCivilStatus.maritalStatus,
      value: true,
    });
  }
  if (
    application.applicant.maritalStatus &&
    showMaritalIncome(application.applicant?.maritalStatus)
  ) {
    if (!application.applicant.spouseGrossYearlyIncome) {
      errors.push({
        name: InputFieldsNameCivilStatus.spouseGrossYearlyIncome,
        value: true,
      });
    }
  }

  if (
    application.coApplicantIncluded === undefined ||
    application.coApplicantIncluded === null
  ) {
    errors.push({
      name: InputFieldsNameCivilStatus.coApplicantIncluded,
      value: true,
    });
  }

  if (
    application.applicant.education === undefined ||
    application.applicant.education === null
  ) {
    errors.push({
      name: InputFieldsNameCivilStatus.education,
      value: true,
    });
  }

  if (
    application.applicant.childrenUnder18 === undefined ||
    application.applicant.childrenUnder18 === null
  ) {
    errors.push({
      name: InputFieldsNameCivilStatus.childrenUnder18,
      value: true,
    });
  }

  if (application.applicant.childrenUnder18 !== NumberOfChildren.None) {
    if (
      application.recievesChildSupport === undefined ||
      application.recievesChildSupport === null
    ) {
      errors.push({
        name: InputFieldsNameCivilStatus.recievesChildSupport,
        value: true,
      });
    }

    if (application.recievesChildSupport) {
      if (!application.childSupportRecievedMonthly) {
        errors.push({
          name: InputFieldsNameCivilStatus.childSupportRecievedMonthly,
          value: true,
        });
      }
    }
  }

  return errors;
};

export const MedlantakerValidationError = (
  coApplicant: ICoApplicant,
  customerSocialNumber: string,
  applicant: IApplicant
) => {
  const errors = [] as PayloadErrorHandling[];

  if (!coApplicant.socialNumber) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.socialNumber}`,
      value: true,
    });
  }

  if (!coApplicant.hasPaymentRemarks) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasPaymentRemarks}`,
      value: true,
    });
  }

  if (
    coApplicant.socialNumber &&
    coApplicant.socialNumber.replace(/\s+/g, "").length !== 11
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.socialNumber}`,
      value: true,
    });
  }

  if (
    coApplicant.socialNumber &&
    !validateNorwegianSSN(coApplicant.socialNumber.replace(/\s+/g, ""))
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.socialNumber}`,
      value: true,
    });
  }

  if (
    coApplicant.socialNumber &&
    customerSocialNumber === coApplicant.socialNumber.replace(/\s+/g, "")
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.socialNumber}`,
      value: true,
    });
  }

  if (!coApplicant.firstName) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.firstName}`,
      value: true,
    });
  }
  if (!coApplicant.lastName) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.lastName}`,
      value: true,
    });
  }

  if (!coApplicant.email) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.email}`,
      value: true,
    });
  }

  if (applicant.email === coApplicant.email) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.email}`,
      value: true,
    });
  }

  if (applicant.email) {
    if (!emailValidation(coApplicant.email)) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.email}`,
        value: true,
      });
    }
  }

  if (applicant.phoneNumber && coApplicant.phoneNumber) {
    const phone = coApplicant.phoneNumber.replace(/\s+/g, "");

    if (applicant.phoneNumber === phone) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.phoneNumber}`,
        value: true,
      });
    }
  }

  if (coApplicant.phoneNumber) {
    const phone = coApplicant.phoneNumber.replace(/\s+/g, "");

    if (phone.length !== 8) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.phoneNumber}`,
        value: true,
      });
    }
    if (!validateNorwegianPhoneNumber(phone)) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.phoneNumber}`,
        value: true,
      });
    }
  }

  if (!coApplicant.phoneNumber) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.phoneNumber}`,
      value: true,
    });
  }

  if (!coApplicant.yearlyGrossIncome) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.yearlyGrossIncome}`,
      value: true,
    });
  }
  if (!coApplicant.monthlyNetIncome) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.monthlyNetIncome}`,
      value: true,
    });
  }
  if (coApplicant.employment === undefined || coApplicant.employment === null) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employment}`,
      value: true,
    });
  }

  if (coApplicant.employment && showEmploymentName(coApplicant.employment)) {
    if (!coApplicant.employerName) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employerName}`,
        value: true,
      });
    }
    if (!coApplicant.employmentSince?.year) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employmentSince}`,
        value: true,
      });
    }
    if (coApplicant.employmentSince?.year) {
      if (isIncludedMonth(coApplicant.employmentSince.year)) {
        if (!coApplicant.employmentSince?.month) {
          errors.push({
            name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employmentSinceMonth}`,
            value: true,
          });
        }
      }
    }
  }

  if (
    coApplicant.livesWithMainApplicant === undefined ||
    coApplicant.livesWithMainApplicant === null
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.livesWithMainApplicant}`,
      value: true,
    });
  }

  if (
    coApplicant.housingSituation === undefined ||
    coApplicant.housingSituation === null
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.housingSituation}`,
      value: true,
    });
  }
  if (coApplicant.housingSituation === HousingSituation.Rents) {
    if (!coApplicant.rentExpense && coApplicant.rentExpense !== 0) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`,
        value: true,
      });
    }
  }

  if (
    coApplicant.housingSituation === HousingSituation.OwnsHouse &&
    (coApplicant.hasMortgage === undefined || coApplicant.hasMortgage === null)
  ) {
    if (
      applicant.maritalStatus !== MaritalStatusType.Married &&
      applicant.maritalStatus !== MaritalStatusType.Cohabitant
    ) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasMortgage}`,
        value: true,
      });
    }
  }

  if (
    coApplicant.housingSituation === HousingSituation.OwnsHouse &&
    coApplicant.hasMortgage &&
    !coApplicant.livesWithMainApplicant
  ) {
    if (!coApplicant.paymentOnMortgageMonthly) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.paymentOnMortgageMonthly}`,
        value: true,
      });
    }
  }
  if (
    coApplicant.childrenUnder18 === undefined ||
    coApplicant.childrenUnder18 === null
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.childrenUnder18}`,
      value: true,
    });
  }
  if (coApplicant.childrenUnder18 !== NumberOfChildren.None) {
    if (
      coApplicant.receivesChildSupport === undefined ||
      coApplicant.receivesChildSupport === null
    ) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.receivesChildSupport}`,
        value: true,
      });
    }

    if (coApplicant.receivesChildSupport) {
      if (!coApplicant.childSupportReceivedMonthly) {
        errors.push({
          name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.childSupportReceivedMonthly}`,
          value: true,
        });
      }
    }
  }

  if (
    coApplicant.hasOtherIncome === undefined ||
    coApplicant.hasOtherIncome === null
  ) {
    errors.push({
      name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasOtherIncome}`,
      value: true,
    });
  }

  if (coApplicant.hasOtherIncome) {
    if (!coApplicant.otherIncome) {
      errors.push({
        name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.otherIncome}`,
        value: true,
      });
    }
  }

  return errors;
};

export const AddPropertyValidationError = (property: IReportedProperty) => {
  const errors = [] as PayloadErrorHandling[];

  if (!property.address) {
    errors.push({
      name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.address}`,
      value: true,
    });
  }
  if (!property.postalCode) {
    errors.push({
      name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.postalCode}`,
      value: true,
    });
  }
  if (!property.type) {
    errors.push({
      name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.type}`,
      value: true,
    });
  }
  if (!property.estimatedValue) {
    errors.push({
      name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.estimatedValue}`,
      value: true,
    });
  }
  if (!property.ownershipPercentage) {
    errors.push({
      name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.ownershipPercentage}`,
      value: true,
    });
  }

  return errors;
};
