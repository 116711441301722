import axios, { AxiosPromise } from "axios";
import {
  FacebookFbclid,
  getTrackingFromStorage,
  GoogleGlid,
  ReadPeak,
  SnapChatClickId,
  TikTokClickId,
  TrackingValues,
} from "../../utils/tracking";

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}

export default interface BankIdLoginResponse {
  redirectUri: string;
}

export interface ApplyForLoanWeb {
  callbackUri: string;
  email: string;
  phoneNumber: string;
  type: string;
}

export const api_initiateBankIdLogin = (
  sessionId: string
): AxiosPromise<BankIdLoginResponse> => {
  let url = "";
  let tracking = {} as TrackingValues;
  if (typeof window !== "undefined") {
    url = window.location.origin;
    tracking = getTrackingFromStorage();
  }

  return axios({
    method: "get",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-auth/login/bankid/web?sessionId=${sessionId}&callbackUri=${url}/bankidreturn`,
    headers: {
      [ReadPeak]: tracking?.rpcid,
      [TikTokClickId]: tracking?.ttclid,
      [SnapChatClickId]: tracking?.ScCid,
      [GoogleGlid]: tracking?.gclid,
      [FacebookFbclid]: tracking?.fbclid,
    },
  });
};

export const apiInitiateApplyForLoan = (
  applyForLoan: ApplyForLoanWeb
): AxiosPromise<BankIdLoginResponse> => {
  let tracking = {} as TrackingValues;
  if (typeof window !== "undefined") {
    tracking = getTrackingFromStorage();
  }

  return axios({
    method: "post",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-auth/web/bankid`,
    data: applyForLoan,
    headers: {
      [GoogleGlid]: tracking?.gclid,
      [FacebookFbclid]: tracking?.fbclid,
      [ReadPeak]: tracking?.rpcid,
      [SnapChatClickId]: tracking?.ScCid,
      [TikTokClickId]: tracking?.ttclid,
    },
  });
};

export const api_connectToken = (
  sessionId: string
): AxiosPromise<TokenResponse> => {
  const formData = new URLSearchParams();

  formData.append("grant_type", "horde_web");
  formData.append("client_id", "hordeWeb");
  formData.append("session_id", sessionId);
  formData.append(
    "scope",
    "control_center.application.control control_center.application.fetch control_center.application.submit control_center.meeting.fetch control_center.meeting.write customer.read debt.read product_application_filter.read creditscore.read residence.read"
  );

  return axios({
    method: "post",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-auth/connect/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: formData,
  });
};
