import { Collapse, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import SelectInput from "../../SelectInput";
import CaretDown from "../../../assets/svgs/caretDown.svg";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import { EmploymentType } from "../../../services/nswag/clients/loanApplicationsClient";
import { EmploymentTypeTranslation } from "../../../utils/emunTranslation";
import { showEmploymentName } from "../../../utils/loanApplicationUtils";
import TextInput from "../../TextInput";
import { getMonths, getYears, isIncludedMonth } from "../../../utils/dateUtils";
import { updateLoanApplicationCoApplicant } from "../../../redux/reducers/loanApplicationReducer";
import { errorPreFixNamingCoApplicant } from "../../../pages/soknad/forbrukslan/medlantaker";
import useCoApplicant from "../../../hooks/useCoApplicant";

const CoApplicantEmployment = () => {
  const dispatch = useDispatch();
  const { coApplicant } = useCoApplicant();

  const years = getYears;
  const months = getMonths;

  const handleEmploymentYearChange = (event: SelectChangeEvent<unknown>) => {
    const includeMonth = isIncludedMonth((event.target.value as number) ?? 0);

    dispatch(
      updateLoanApplicationCoApplicant({
        name: event.target.name.replace(errorPreFixNamingCoApplicant, ""),
        value: {
          year: event.target.value as number,
          month: includeMonth ? 0 : 1,
          day: 1,
        },
        index: 0,
      })
    );
  };

  const handleEmploymentMonthChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      updateLoanApplicationCoApplicant({
        name: event.target.name.replace(errorPreFixNamingCoApplicant, ""),
        value: {
          year: coApplicant.employmentSince.year,
          month: event.target.value as number,
          day: 1,
        },
        index: 0,
      })
    );
  };

  return (
    <>
      <SelectInput
        label="Medlåntakers arbeidssituasjon"
        id="coapplicant-employmentType-select"
        value={coApplicant?.employment}
        onChange={(event) => {
          dispatch(
            updateLoanApplicationCoApplicant({
              name: InputFieldsCoApplicant.employment,
              value: event.target.value,
              index: 0,
            })
          );
        }}
        maxWidth="300px"
        variant="outlined"
        IconComponent={CaretDown}
        name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employment}`}
      >
        {(Object.keys(EmploymentType) as Array<keyof typeof EmploymentType>)
          .filter((l) => EmploymentTypeTranslation(EmploymentType[l]) !== "")
          .map((key) => {
            return (
              <MenuItem key={key} value={EmploymentType[key]}>
                {EmploymentTypeTranslation(EmploymentType[key])}
              </MenuItem>
            );
          })}
      </SelectInput>
      {showEmploymentName(coApplicant?.employment) && (
        <>
          <TextInput
            id="coapplicant-employer-name"
            label="Medlåntakers arbeidsgiver"
            variant="outlined"
            type="text"
            maxWidth="300px"
            value={coApplicant?.employerName}
            onChange={(event) => {
              dispatch(
                updateLoanApplicationCoApplicant({
                  name: InputFieldsCoApplicant.employerName,
                  value: event.target.value,
                  index: 0,
                })
              );
            }}
            name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employerName}`}
          />
          <Stack direction="row" gap="24px">
            <SelectInput
              label="Siden"
              id="year-select"
              name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employmentSince}`}
              value={coApplicant?.employmentSince?.year}
              onChange={handleEmploymentYearChange}
              width="150px"
              variant="outlined"
              IconComponent={CaretDown}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </SelectInput>
            <Stack>
              <Collapse
                orientation="horizontal"
                in={isIncludedMonth(coApplicant?.employmentSince?.year ?? 0)}
              >
                <SelectInput
                  label="Måned"
                  id="month-select"
                  name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.employmentSinceMonth}`}
                  value={coApplicant?.employmentSince?.month}
                  onChange={handleEmploymentMonthChange}
                  width="150px"
                  variant="outlined"
                  IconComponent={CaretDown}
                >
                  {months.map((month) => {
                    return (
                      <MenuItem key={month.value} value={month.value}>
                        {month.name}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Collapse>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default CoApplicantEmployment;
