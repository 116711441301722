import React from "react";
import { Stack, Typography } from "@mui/material";
import COLORS from "../../../../../constants/colors";
import PartnerLogoCircle from "../../../PartnerLogoCircle";

export default function PartnerBankSmallCard({ name }: { name: string }) {
  return (
    <Stack
      bgcolor={COLORS.BRIGHT_BLUE[20]}
      borderRadius="12px"
      gap="16px"
      direction="row"
      alignItems="center"
      py="16px"
      px="34px"
    >
      <PartnerLogoCircle partnerName={name} />
      <Typography variant="body" color={COLORS.DARK_BLUE[100]}>
        {name}
      </Typography>
    </Stack>
  );
}
