import axios from "axios";
import * as Sentry from "@sentry/react";
import { navigate } from "gatsby";
import { LoanApplicationsClient } from "./nswag/clients/loanApplicationsClient";
import { ResidenceClient } from "./nswag/clients/residenceClient";
import { NavigationNames } from "../constants/navigationNames";
import { getToken } from "./accessTokenHandler";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Ocp-Apim-Subscription-Key": process.env.API_KEY ?? "",
  },
  baseURL: process.env.API_URL,
});

axiosInstance.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function (error) {
    // Send error to Sentry
    Sentry.captureException(error);

    const errorResponse = error.response;
    if (errorResponse) {
      // Clear session and throw user out, since token is out
      if (errorResponse.status === 401) {
        navigate(NavigationNames.InActive);
      }
      if (errorResponse.status === 500) {
        navigate(NavigationNames.SOKNAD.ERROR);
      }
    }

    return Promise.reject(error);
  }
);
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const GetLoanApplicationClient = () => {
  return new LoanApplicationsClient("/loan-applications", axiosInstance);
};

export const GetResidenceClient = () => {
  return new ResidenceClient("/s-residence-web", axiosInstance);
};

export const setAuthorizationHeader = (accessToken: string): any => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const getCurrentToken = (): string | undefined =>
  axiosInstance.defaults.headers.common.Authorization?.toString().split(" ")[1];

export default axiosInstance;
