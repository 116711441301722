import { AxiosPromise } from "axios";
import axios from "../axiosInstance";

export interface FetchDebtResponse {
  ssn: string;
  timeStamp: string;
  loans: Loan[];
  list?: any;
  summary?: any;
  numberOfCreditorsAnswered: number;
  numberOfCreditorsMissing: number;
  customerId: number;
  registerType: number;
  isValidConsent: boolean;
  consentStatus: number;
  isApiFlow: boolean;
  hasCreditCards: boolean;
  hasRepaymentLoans: boolean;
}

export interface Loan {
  creditorName: string;
  loan: LoanData;
}

export interface LoanData {
  id: number;
  isChargeCard: boolean;
  isRepaymentLoan: boolean;
  isCreditCard: boolean;
  type: string;
  timestamp: string;
  coBorrower: number;
  creditLimit: number;
  interestBearingBalance: number;
  nonInterestBearingBalance: number;
  nominalInterestRate: number;
  installmentCharges: number;
  installmentChargePeriod: string;
  originalBalance: number;
  balance: number;
  terms?: number;
  financialInstitutionName?: any;
  processedTime: string;
  loanRootId: number;
  propsId: number;
  props?: any;
}

export const apiFetchDebtAfterBankId = (): AxiosPromise<FetchDebtResponse> => {
  return axios({
    method: "POST",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-debt-proxy-web/web-debt`,
  });
};

export const apiFetchDebt = (): AxiosPromise<FetchDebtResponse> => {
  return axios({
    method: "GET",
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `${process.env.API_URL}/s-debt-proxy-web/web-debt`,
  });
};
