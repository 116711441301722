/*
 * ──────────────── TYPOGRAPHY ────────────────
 * In this section we define our custom typography variants and extend the MUI theme to include them
 * in the theme and make them available throughout our application in the MUI Typography component.
 *
 * This allows us to use our custom typography variants like this:
 * <Typography variant="newVariantName">{...}</Typography>
 *
 * ──────────────── HOW TO ADD NEW VARIANT ────────────────
 * Simply add a new object to the 'customTypographyVariants' object with the following structure:
 *  'newVariantName': {
 *      fontFamily: 'fontFamily', // Preferably use the InterFontList variable.
 *      fontWeight: 'fontWeight', // Number
 *      fontSize:   'fontSize',   // Number
 *      lineHeight: 'lineHeight', // Number
 *      // Add any other CSS properties you want to include in the variant.
 *  }
 *
 * ──────────────── NOTES ────────────────
 * You cannot override the font size in the component prop. This is because we use the 'responsiveFontSizes' function
 * to make our typography variants responsive. This function calculates the font size based on the theme's breakpoints.
 * That means you cant do this:
 *  <Typography variant="newVariantName" fontSize={"20px"}>{...}</Typography>
 *
 * You can still override the font size in the component prop by using the 'sx' prop like this:
 *  <Typography variant="newVariantName" sx={{ fontSize: '20px !important' }}>{...}</Typography>
 *
 * But this is not recommended because it breaks the responsive font size feature and looks messy.
 * Try to add a new variant if you can. Use the 'sx' prop only if you have to.
 */

import { InterFontList, ErlikFontList, BiroFontList } from "../utils";

// Define an object to hold custom typography styles. Each key represents a unique typography variant,
export const customTypographyVariants = {
  h1ExtraLargeCar: {
    fontFamily: "Erlik",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 140,
    lineHeight: 0.9,
  },
  h2BiroCar: {
    fontFamily: BiroFontList,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 40,
    lineHeight: 0.9,
  },
  CalenderText: {
    fontFamily: InterFontList,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
  },
  CalenderTitle: {
    fontFamily: InterFontList,
    fontWeight: 800,
    fontSize: 18,
    lineHeight: 1.6,
  },
  hatchesTitle: {
    fontFamily: "Erlik",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 64,
    lineHeight: 1.5,
  },
  ambassadorTitle: {
    fontFamily: "Erlik",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 32,
    lineHeight: 1.5,
  },
  lead: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 22,
    lineHeight: 1.5,
  },
  body: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.6,
  },
  bodyBold: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.6,
  },
  bodySmall: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.6,
  },
  bodySmallBold: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.6,
  },
  label: {
    FontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.6,
  },
  labelSmall: {
    FontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.6,
  },
  labelBold: {
    FontFamily: InterFontList,
    fontWeight: 600,
    fontSize: 22,
    lineHeight: 1.6,
  },
  labelBoldBig: {
    FontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.6,
  },
  h1Large: {
    fontFamily: ErlikFontList,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 90,
    lineHeight: 1.1,
  },
  h1ExtraLarge: {
    fontFamily: "Erlik",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 180,
    lineHeight: 0.9,
  },
  h1Biro: {
    fontFamily: "Biro",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 60,
    lineHeight: 0.9,
  },
  h1Article: {
    fontFamily: InterFontList,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 54,
    lineHeight: 1,
  },
  h2Small: {
    fontFamily: "Erlik",
    fontWeight: 900,
    fontSize: 32,
    lineHeight: 1,
  },
  h2Biro: {
    fontFamily: BiroFontList,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 40,
    lineHeight: 0.9,
  },
  h2Inter: {
    fontFamily: InterFontList,
    fontWeight: 800,
    fontSize: 41,
    lineHeight: 1.25,
  },
  h5: {
    fontFamily: InterFontList,
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 1.35,
  },
  articleCardTitle: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 1.5,
  },
  campaignCardTitle: {
    fontFamily: "Erlik",
    fontWeight: 900,
    fontSize: 32,
    lineHeight: 1.25,
  },
  h3Erlik: {
    fontFamily: "Erlik",
    fontWeight: 900,
    fontSize: 41,
    lineHeight: 1.25,
  },
  campaignCardDate: {
    fontFamily: InterFontList,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
  },
  quoteText: {
    fontFamily: "Erlik",
    fontWeight: 900,
    fontSize: 41,
    lineHeight: 1.25,
  },
  quoteSignature: {
    fontFamily: "Biro",
    fontSize: 32,
    lineHeight: 1,
  },
  mediaCardTitle: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.25,
  },
  mediaCardDescription: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.6,
  },
  textError404: {
    fontFamily: "Biro",
    fontSize: 40,
    lineHeight: 1.6,
  },
  campaignFinishedTitle: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.5,
  },
  dropdownText: {
    textDecoration: "underline",
    textDecorationThickness: "2px",
    textUnderlineOffset: "6px",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  loanCalculatorTitle: {
    fontFamily: "Erlik",
    fontWeight: 900,
    fontSize: 32,
    lineHeight: 1.25,
  },
  loanCalculatorInputLabel: {
    fontFamily: InterFontList,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.6,
  },
  loanCalculatorEstimatedMonthly: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 33,
    lineHeight: 1.25,
  },
  loanCalculatorEstimateDesc: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.6,
  },
  link: {
    fontFamily: InterFontList,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.5,
  },
  loanCardTitle: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.35,
  },
  loanCardLabelBold: {
    fontFamily: InterFontList,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.6,
  },
  loanCardLabel: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.6,
  },
  loanBiroH3: {
    fontFamily: "Biro",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1,
  },
  loanCalculatorInputError: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.6,
  },
  loanInterestExampleTitle: {
    fontFamily: InterFontList,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.6,
  },
  loanInterestExampleDescription: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.6,
  },
  articleAuthor: {
    fontFamily: InterFontList,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.6,
  },
  appOBTitle: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.35,
  },
  appDownloadPopperTitle: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.35,
  },
  inputLabel: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.6,
  },
  inputDescription: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 1.5,
  },
  loanStartInfoProviderName: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.6,
  },
  loanStartInfoProviderProvides: {
    fontFamily: InterFontList,
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 1.6,
  },
  loanApplicationSummaryInfoTitle: {
    fontFamily: InterFontList,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
  },
  loanApplicationSummaryInfoTitleUnderLined: {
    fontFamily: InterFontList,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    textDecoration: "underline",
  },
  loanApplicationSummaryInfoText: {
    fontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
  },
  loanOfferCardAmount: {
    fontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.25,
  },
  mobileLabel: {
    FontFamily: InterFontList,
    fontWeight: 400,
    fontSize: 11,
    lineHeight: 1.6,
  },
  mobileValue: {
    FontFamily: InterFontList,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.6,
  },
  campaignCardChristmas: {
    fontFamily: InterFontList,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
    fontStyle: "italic",
  },
  // Additional typography variants follow the same structure.
};

// Declare a module augmentation for '@mui/material/Typography'.
// This tells TypeScript that our custom typography variants are valid values for the 'variant' prop in the Typography component.
declare module "@mui/material/Typography" {
  // Define a type that maps each custom typography variant name to a boolean.
  type CustomTypographyVariantProps = {
    [Key in TypographyVariantsKeys]?: true;
  };

  // Extend the 'TypographyPropsVariantOverrides' interface with our custom typography variant props.
  interface TypographyPropsVariantOverrides
    extends CustomTypographyVariantProps {}
}

// Define a type for the keys of the 'customTypographyVariants' object.
type TypographyVariantsKeys = keyof typeof customTypographyVariants;

// Convert the keys of the 'customTypographyVariants' object into an array.
// We use this later when we specify which variants should be responsive.
export const customTypographyVariantsNameList: TypographyVariantsKeys[] =
  Object.keys(customTypographyVariants) as TypographyVariantsKeys[];
