import React from "react";
import useSiteMetadata from "../hooks/useSiteMetadata";
import { baseUrlWithoutSlash, getFirstMenuSegment } from "../utils/url";
import { Category } from "../interfaces/sanity/legacySanityClasses";
import { BaseUrl, getHrefUrl } from "../constants/url";
import seoForside from "../assets/images/seo-forsiden.png";

type SEOProps = {
  title: string;
  description: string;
  pathname?: string;
  children?: React.ReactNode;
  image?: string;
  keywords?: string[];
  canonical?: string;
  categories?: Category[];
  structuredDataArticle?: {
    author?: string;
    datePublished?: string;
  };
};

const SEO = ({
  title,
  description,
  pathname,
  children,
  image,
  keywords,
  canonical,
  categories,
  structuredDataArticle,
}: SEOProps) => {
  // Site metadata
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  // SEO object
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || seoForside,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  // Keywords
  const keywordsFormatted = keywords?.join(", ");
  const baseUrl = baseUrlWithoutSlash();

  const getSeoImage = () => {
    const containsHttp: boolean = seo.image.includes("http");
    if (containsHttp) {
      return seo.image;
    }
    return baseUrl + seo.image;
  };

  const seoImage = getSeoImage();

  const getTitle = () => {
    if (categories?.length === 1) {
      return `${seo.title} - ${categories[0].title} - Horde`;
    }
    return `${seo.title}`;
  };

  const getHref = () => {
    const href = getHrefUrl();
    if (href) {
      return href;
    }
    return seo.url;
  };

  const seoTitle = getTitle();
  const href = getHref();

  return (
    <>
      {/* SEO */}
      <html lang="nb-NO" />
      <link rel="canonical" href={canonical} />
      <title>{seoTitle}</title>
      <meta name="title" content={seoTitle} />
      <meta name="keywords" content={keywordsFormatted} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seoImage} />
      <meta name="author" content="Horde" />
      <meta property="og:site_name" content="Horde" />

      {/*  Facebook Meta Tags */}
      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seoImage} />
      <meta property="fb:app_id" content="988429174829530" />

      {/*  Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@HordeApp" />
      <meta name="twitter:creator" content="@HordeApp" />
      <meta property="twitter:domain" content="horde.no" />
      <meta property="twitter:url" content={href} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seoImage} />

      {/* LinkedIn */}
      <meta name="author" content="Horde" />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
      />

      {/* Facebook & Unknown */}
      <meta name="29a51af8e6cc960" content="06e5e722d2c5eae83dd60f1157d29507" />
      <meta
        name="facebook-domain-verification"
        content="n65wgn0hu74a2mggufotkxn05vsqjj"
      />

      {/* Metricool */}
      <img
        src="https://tracker.metricool.com/c3po.jpg?hash=bf93bc6c9ee6573f370da0c9319502b7"
        alt="tracker"
      />

      {/* Structured data */}
      {/* Article */}
      {/* Only show on article pages */}
      {structuredDataArticle && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": getHref(),
            },
            author: {
              "@type": "Person",
              name: structuredDataArticle?.author,
            },
            publisher: {
              "@type": "Organization",
              name: "Horde AS",
              logo: {
                "@type": "ImageObject",
                url: `${BaseUrl}/img/structured-data-horde-logo.png`,
              },
            },
            headline: seo.title,
            image: seoImage,
            datePublished: structuredDataArticle?.datePublished,
          })}
        </script>
      )}
      {/* Corporation */}
      {/* Only show on homepage and about us */}
      {["/", "/om-horde"].includes(getFirstMenuSegment()) && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            name: "Horde AS",
            alternateName: "Horde",
            url: "https://horde.no/",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Lars Hilles Gate 20A",
              addressLocality: "Bergen",
              addressRegion: "Vestland",
              postalCode: "5008",
              addressCountry: "Norway",
            },
            logo: `${BaseUrl}/img/structured-data-horde-logo.png`,
            sameAs: [
              "https://www.facebook.com/horde.no",
              "https://www.instagram.com/horde.no/",
              "https://www.youtube.com/@hordeapp",
              "https://linkedin.com/company/hordeapp",
              "https://horde.no/",
            ],
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "55902900",
              contactType: "Customer Service",
              areaServed: "NO",
              availableLanguage: "Norwegian",
            },
          })}
        </script>
      )}

      {/* Content */}

      {children}
    </>
  );
};

export default SEO;
