import { api_sendClickEvent, TrackingEvent } from "../services/api/tracking";

export const GoogleGlid = "gclid";
export const FacebookFbclid = "fbclid";
export const GoogleSrc = "gclsrc";
export const ReadPeak = "rpcid";
export const TikTokClickId = "ttclid";
export const SnapChatClickId = "ScCid";

export interface TrackingValues {
  gclid: string | null;
  fbclid: string | null;
  ttclid: string;
  ScCid: string;
  rpcid: string;
}

const getCookie = (name: string) => {
  const cookie = {};
  document.cookie.split(";").forEach(function (el) {
    const [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[name];
};

export const getTrackingFromStorage = () => {
  if (typeof window !== "undefined") {
    const gclid = localStorage.getItem(GoogleGlid);
    const fbclid = localStorage.getItem(FacebookFbclid);
    const tikTokClickId = getCookie(TikTokClickId); // TikTok Click ID
    // const tikTokCookieId = getCookie("_ttp");
    const snapChatClickId = getCookie(SnapChatClickId);
    // const snapChatCookieId = getCookie("sc_cookie1");
    const rpcid = localStorage.getItem(ReadPeak);
    // rpcid

    return {
      gclid: gclid ? JSON.parse(gclid).value : null,
      fbclid: fbclid ? JSON.parse(fbclid).value : null,
      ttclid: tikTokClickId || "",
      ScCid: snapChatClickId || "",
      rpcid: rpcid || "",
    } as TrackingValues;
  }

  return {
    gclid: null,
    fbclid: null,
  } as TrackingValues;
};

export const removeTrackingFromStorage = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(GoogleGlid);
    localStorage.removeItem(FacebookFbclid);
    localStorage.removeItem(ReadPeak);
    localStorage.removeItem(TikTokClickId);
    localStorage.removeItem(SnapChatClickId);
  }
};

export const sendTrackings = (
  eventName: TrackingEvent,
  loanTypeEvent: string,
  customEvent?: string
) => {
  if (typeof window !== "undefined") {
    if (customEvent) {
      // Sending event to google analytics
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.dataLayer.push({
        event: customEvent,
        loanType: loanTypeEvent,
      });
    }
    const trackings = getTrackingFromStorage();
    const hasValue = Object.values(trackings).some((value) => value);
    if (hasValue) {
      api_sendClickEvent(eventName, trackings).then((resp) => {
        if (
          resp?.status === 200 &&
          eventName === TrackingEvent.LoanRequestSent
        ) {
          removeTrackingFromStorage();
        }
      });
    }
  }
};
export const getParam = (p: string, url: string): string | null => {
  const match = RegExp(`[?&]${p}=([^&]*)`).exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const getExpiryRecord = (value: string, expiredValue: number) => {
  const expiryPeriod = expiredValue; // 90 day expiry in milliseconds
  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value,
    expiryDate,
  };
};
