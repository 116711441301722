import { Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import COLORS from "../../../../../../constants/colors";
import BankLogo from "../../../../BankLogo";
import { IPartnerSubmissionClientView } from "../../../../../../services/nswag/clients/loanApplicationsClient";
import ComboOfferCard from "./ComboOfferCard";
import OfferCard from "./OfferCard";
import { useAppDispatch } from "../../../../../../hooks/reduxHooks";
import { NavigationNames } from "../../../../../../constants/navigationNames";
import { acceptApplicationOffer } from "../../../../../../redux/reducers/submittedApplications/submittedApplicationsReducer";

export default function PartnerBankWithOffer({
  applicationId,
  partner,
}: IProps) {
  const dispatch = useAppDispatch();
  const [highlighted, setHighlighted] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const componentCenterY = useRef<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const viewportCenterY = scrollTop + window.innerHeight / 2;
      const diff = Math.abs(viewportCenterY - componentCenterY.current);

      if (diff > 400) {
        setHighlighted(false);
      }
    };

    if (highlighted) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [highlighted]);

  const highlightOfferAndScrollToPosition = () => {
    setHighlighted(true);
    if (componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      componentCenterY.current = rect.top + scrollTop + rect.height / 2;
    }
  };

  const onAcceptOffer = (dual: boolean) => {
    if (!highlighted) {
      highlightOfferAndScrollToPosition();
      return;
    }

    dispatch(
      acceptApplicationOffer({
        id: applicationId,
        pid: partner.partnerId,
        dual,
      })
    )
      .unwrap()
      .then(() => {
        navigate(NavigationNames.TILBUD.SIGNERING(applicationId), {
          replace: true,
        });
      })
      .catch(() => {
        navigate(NavigationNames.SOKNAD.ERROR);
      });
  };

  const onCancelHighlight = () => {
    setHighlighted(false);
  };

  return (
    <>
      {highlighted && (
        <Stack
          onClick={() => setHighlighted(false)}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)", // Juster opasiteten etter behov
            zIndex: 999, // Sørg for at overlayet er over resten av innholdet
            cursor: "pointer",
          }}
        />
      )}
      <Stack
        ref={componentRef}
        py={{ xs: "16px", md: "24px" }}
        px={{ xs: "16px", md: "34px" }}
        bgcolor={COLORS.BRIGHT_BLUE[20]}
        borderRadius="12px"
        gap="32px"
        sx={{
          position: highlighted ? "relative" : "static",
          zIndex: highlighted ? 1000 : "auto", // Sørg for at z-index er høyere enn overlayet
        }}
      >
        <Stack direction="row" gap="16px" alignItems="center">
          <Stack
            bgcolor="#FFF"
            height={{ xs: "48px", md: "80px" }}
            width={{ xs: "48px", md: "80px" }}
            minWidth={{ xs: "48px", md: "80px" }}
            borderRadius="50%"
            justifyContent="center"
            alignItems="center"
            p="10px"
          >
            <BankLogo isSmall bankName={partner.partnerName} />
          </Stack>
          <Stack>
            <Typography variant="body" color={COLORS.DARK_BLUE[100]}>
              {partner.partnerName}
            </Typography>
          </Stack>
        </Stack>
        <OfferCard
          offer={partner.primaryOffer}
          highlighted={highlighted}
          onAcceptOffer={() => onAcceptOffer(false)}
          onCancelAction={onCancelHighlight}
        />
        <OfferCard
          offer={partner.dualOffer}
          highlighted={highlighted}
          onAcceptOffer={() => onAcceptOffer(true)}
          onCancelAction={onCancelHighlight}
        />
        <ComboOfferCard
          offer={partner.comboOffer}
          highlighted={highlighted}
          onAcceptOffer={() => onAcceptOffer(false)}
          onCancelAction={onCancelHighlight}
        />
      </Stack>
    </>
  );
}
export interface IProps {
  applicationId: string;
  partner: IPartnerSubmissionClientView;
}
