import React, { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { navigate } from "gatsby";
import SEO from "../../../components/Seo";
import LoanFlowWrapper from "../../../components/layout/LoanFlowWrapper";
import COLORS from "../../../constants/colors";
import PrivateRoute from "../../../components/soknad/PrivateRoute";

import { SelfReportedPaymentRemarks } from "../../../services/nswag/clients/loanApplicationsClient";
import TextInput from "../../../components/TextInput";
import TextMaskCustom from "../../../components/TextMaskCustom";
import NextPrevButtons from "../../../components/soknad/NextPrevButtons";
import {
  getLoanApplication,
  refreshErrorHandlingStatus,
} from "../../../redux/reducers/loanApplicationReducer";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import FormCardStack from "../../../components/soknad/FormCardStack";
import InputFieldWrapper from "../../../components/soknad/InputFieldWrapper";
import ButtonToggleGroupWrapper from "../../../components/soknad/ButtonToggleGroupWrapper";
import {
  validateNorwegianPhoneNumber,
  validateNorwegianSSN,
} from "../../../utils/stringValidation";
import CoApplicantPaymentRemark from "../../../components/soknad/coapplicant/CoApplicantPaymentRemark";
import CoApplicantIncome from "../../../components/soknad/coapplicant/CoApplicantIncome";
import CoApplicantOtherIncome from "../../../components/soknad/coapplicant/CoApplicantOtherIncome";
import CoApplicantEmployment from "../../../components/soknad/coapplicant/CoApplicantEmployment";
import CoApplicantHousing from "../../../components/soknad/coapplicant/CoApplicantHousing";
import CoApplicantChildren from "../../../components/soknad/coapplicant/CoApplicantChildren";
import useCoApplicant from "../../../hooks/useCoApplicant";
import { NavigationNames } from "../../../constants/navigationNames";
import { goBackOneStep } from "../../../redux/reducers/stepsReducer";
import SoknadTitle from "../../../components/soknad/SoknadTitle";

export const errorPreFixNamingCoApplicant = "coApplicant";

const CoapplicantPage = () => {
  const { application } = useSelector(getLoanApplication);
  const dispatch = useDispatch();

  const {
    coApplicant,
    handleUpdate,
    validateNames,
    validateBeforeNext,
    ssn,
    continueWithoutCoApplicant,
  } = useCoApplicant();

  const showCoApplicantPaymentMarkComponent =
    coApplicant?.hasPaymentRemarks === SelfReportedPaymentRemarks.Yes;

  const socialNumberHelperText = () => {
    if (coApplicant?.socialNumber) {
      if (ssn === coApplicant?.socialNumber.replace(/\s+/g, "")) {
        return "Du kan ikke være medlåntaker på din egen søknad";
      }
      if (
        !validateNorwegianSSN(coApplicant?.socialNumber.replace(/\s+/g, ""))
      ) {
        return "Ikke gyldig fødselsnummer";
      }
    }

    return "Fyll ut 11 siffer";
  };

  const emailHelperText = () => {
    if (coApplicant?.email) {
      if (application.applicant.email === coApplicant?.email) {
        return "Du kan ikke bruke samme e-post addresse som deg selv";
      }
    }

    return "Oppgi e-post";
  };

  const phoneNumberHelperText = () => {
    if (coApplicant?.phoneNumber) {
      if (
        application.applicant.phoneNumber ===
        coApplicant?.phoneNumber.replace(/\s+/g, "")
      ) {
        return "Du kan ikke bruke samme tlf nummer som deg selv";
      }
      if (
        !validateNorwegianPhoneNumber(
          coApplicant?.phoneNumber?.replace(/\s+/g, "")
        )
      ) {
        return "Ikke gyldig tlf nummer";
      }
    }

    return "Oppgi telefonummer";
  };

  useEffect(() => {
    if (!application.coApplicantIncluded) {
      dispatch(goBackOneStep());
    }
  }, []);

  return (
    <PrivateRoute>
      <LoanFlowWrapper validateBeforeNext={validateBeforeNext}>
        <Stack gap="24px">
          <FormCardStack
            shadowed
            gap="32px"
            py={{ md: "48px" }}
            px={{ md: "40px" }}
            pl={{ xs: "8px" }}
            pr={{ xs: "8px" }}
          >
            <SoknadTitle title="Medlåntaker" />
            <InputFieldWrapper>
              <TextInput
                name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.socialNumber}`}
                id={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.socialNumber}`}
                value={coApplicant?.socialNumber}
                label="Medlåntakers fødselsnummer (11 siffer)"
                variant="outlined"
                type="text"
                slotProps={{
                  htmlInput: {
                    maxLength: 11,
                    inputMode: "numeric",
                  },
                  input: {
                    inputComponent: TextMaskCustom as any,
                    inputProps: {
                      mask: "000000 00000",
                    },
                    sx: {
                      maxWidth: "200px",
                    },
                  },
                }}
                onBlur={() => {}}
                errorText={socialNumberHelperText()}
                onChange={handleUpdate}
              />
              <TextInput
                value={coApplicant?.firstName}
                name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.firstName}`}
                id="coapplicant-first-name"
                label="Fornavn"
                variant="outlined"
                type="text"
                slotProps={{
                  input: {
                    sx: {
                      maxWidth: "300px",
                    },
                  },
                }}
                errorText="Oppgi fornavn"
                onChange={handleUpdate}
              />
              <TextInput
                name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.lastName}`}
                id="coapplicant-last-name"
                label="Etternavn"
                variant="outlined"
                type="text"
                slotProps={{
                  input: {
                    sx: {
                      maxWidth: "300px",
                    },
                  },
                }}
                errorText="Oppgi etternavn"
                value={coApplicant?.lastName}
                onChange={handleUpdate}
              />
            </InputFieldWrapper>
            <ButtonToggleGroupWrapper gap="20px">
              <CoApplicantPaymentRemark />
              {showCoApplicantPaymentMarkComponent && (
                <>
                  <Typography variant="bodySmall" color={COLORS.RED[100]}>
                    Hvis medlåntaker har betalingsanmerkninger, vil det gi
                    avslag hos bankene. Vi anbefaler å søke uten denne
                    medlåntakeren.
                  </Typography>
                  <Stack display="flex" alignItems="flex-start">
                    <Button
                      variant="outlinedToggle"
                      onClick={() => {
                        continueWithoutCoApplicant();
                      }}
                    >
                      Fortsett uten medlåntaker
                    </Button>
                  </Stack>
                </>
              )}
            </ButtonToggleGroupWrapper>
            {!showCoApplicantPaymentMarkComponent && (
              <>
                <InputFieldWrapper>
                  <TextInput
                    id="coapplicant-email-input"
                    label="Medlåntakers e-post"
                    type="email"
                    slotProps={{
                      htmlInput: {
                        inputMode: "email",
                      },
                    }}
                    errorText={emailHelperText()}
                    name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.email}`}
                    value={coApplicant?.email}
                    maxWidth="300px"
                    onChange={handleUpdate}
                  />
                  <TextInput
                    id="coapplicant-phone-input"
                    label="Medlåntakers telefonnummer (8 siffer)"
                    type="tel"
                    errorText={phoneNumberHelperText()}
                    name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.phoneNumber}`}
                    slotProps={{
                      htmlInput: {
                        maxLength: 11,
                        inputMode: "tel",
                      },
                      input: {
                        inputComponent: TextMaskCustom as any,
                        inputProps: {
                          mask: "00 00 00 00",
                        },
                        sx: {
                          maxWidth: "200px",
                        },
                      },
                    }}
                    value={coApplicant?.phoneNumber}
                    onChange={handleUpdate}
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <CoApplicantIncome />
                  <CoApplicantEmployment />
                </InputFieldWrapper>
                <CoApplicantOtherIncome />
                <ButtonToggleGroupWrapper gap="24px">
                  <CoApplicantHousing />
                </ButtonToggleGroupWrapper>
                <ButtonToggleGroupWrapper>
                  <CoApplicantChildren />
                </ButtonToggleGroupWrapper>
              </>
            )}
          </FormCardStack>
        </Stack>
        <NextPrevButtons
          nextButton={() => {
            if (
              coApplicant.hasPaymentRemarks === SelfReportedPaymentRemarks.Yes
            ) {
              navigate(NavigationNames.SOKNAD.COAPPLICANT_PAYMENTMARK);
              return true;
            }
            return false;
          }}
          prevButton={() => {
            if (
              coApplicant.hasPaymentRemarks === SelfReportedPaymentRemarks.Yes
            ) {
              dispatch(refreshErrorHandlingStatus(validateNames));
            }
            dispatch(goBackOneStep());
          }}
          validateNames={validateNames}
          validateBeforeNext={validateBeforeNext}
        />
      </LoanFlowWrapper>
    </PrivateRoute>
  );
};
export default CoapplicantPage;

export function Head() {
  return (
    <SEO
      title="Søknad - Medlåntaker"
      description="Side for å fylle inn informasjon om medlåntaker"
    />
  );
}
