export const getYears = Array.from(
  { length: 71 },
  (_, i) => new Date().getFullYear() - i
);
export const getMonths = Array.from({ length: 12 }, (_, i) => {
  const monthName = new Date(0, i).toLocaleString("no", { month: "long" });
  return {
    name: monthName.charAt(0).toUpperCase() + monthName.slice(1),
    value: i + 1,
  };
});

export const isIncludedMonth = (value: number) => {
  const years = getYears;
  if (value === years[0] || value === years[1]) {
    return true;
  }
  return false;
};
