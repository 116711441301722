import { Collapse, InputAdornment, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonToggleGroup from "../../ButtonToggle";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import {
  HousingSituation,
  MaritalStatusType,
} from "../../../services/nswag/clients/loanApplicationsClient";
import TextInput from "../../TextInput";
import { handleNumberRangeInputValidation } from "../../../utils/warningValidationUtils";
import { errorPreFixNamingCoApplicant } from "../../../pages/soknad/forbrukslan/medlantaker";
import {
  getLoanApplicationForm,
  updateLoanApplicationCoApplicant,
} from "../../../redux/reducers/loanApplicationReducer";
import CustomNumericFormat from "../../CustomNumericFormat";
import useCoApplicant from "../../../hooks/useCoApplicant";

const CoApplicantHousing = () => {
  const dispatch = useDispatch();
  const { applicant } = useSelector(getLoanApplicationForm);
  const { coApplicant, handleUpdate } = useCoApplicant();

  const standardButtonOptions = [
    { label: "Ja", value: true },
    { label: "Nei", value: false },
  ];
  const housingSituationOptions = [
    { label: "Eier", value: HousingSituation.OwnsHouse },
    { label: "Leier", value: HousingSituation.Rents },
  ];

  useEffect(() => {
    dispatch(
      updateLoanApplicationCoApplicant({
        name: InputFieldsCoApplicant.livesWithMainApplicant,
        value:
          applicant.maritalStatus === MaritalStatusType.Married ||
          applicant.maritalStatus === MaritalStatusType.Cohabitant,
        index: 0,
      })
    );
    if (!coApplicant?.yearlyGrossIncome) {
      if (
        applicant.maritalStatus === MaritalStatusType.Married ||
        applicant.maritalStatus === MaritalStatusType.Cohabitant
      ) {
        dispatch(
          updateLoanApplicationCoApplicant({
            name: InputFieldsCoApplicant.yearlyGrossIncome,
            value: applicant.spouseGrossYearlyIncome,
            index: 0,
          })
        );
      }
    }
  }, [applicant?.maritalStatus, coApplicant?.yearlyGrossIncome]);

  return (
    <>
      <ButtonToggleGroup
        label="Eier eller leier medlåntaker bolig?"
        labelVariant="inputLabel"
        buttons={housingSituationOptions}
        onSelectedChange={(value) => {
          handleUpdate({
            target: {
              name: `${InputFieldsCoApplicant.housingSituation}`,
              value,
            },
          });
          handleUpdate({
            target: {
              name: `${InputFieldsCoApplicant.hasMortgage}`,
              value: value === HousingSituation.Rents ? false : undefined,
            },
          });
        }}
        name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.housingSituation}`}
        value={coApplicant?.housingSituation}
      />
      <Stack>
        <Collapse in={coApplicant?.housingSituation === HousingSituation.Rents}>
          <CustomNumericFormat
            allowNegative={false}
            label="Hva betaler medlåntaker i husleie i måneden?"
            thousandSeparator=" "
            customInput={TextInput}
            errorText="Oppgi beløp"
            type="text"
            variant="outlined"
            onValueChange={(value) => {
              handleUpdate({
                target: {
                  name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`,
                  value: value.floatValue,
                },
              });
            }}
            onBlur={() => {
              dispatch(
                handleNumberRangeInputValidation(
                  1000,
                  50000,
                  coApplicant.rentExpense ?? 0,
                  `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`
                )
              );
            }}
            name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`}
            value={coApplicant?.rentExpense}
            slotProps={{
              htmlInput: {
                maxLength: 7,
                inputMode: "numeric",
              },
              input: {
                sx: {
                  maxWidth: "150px",
                },
                endAdornment: (
                  <InputAdornment position="end">kr</InputAdornment>
                ),
              },
            }}
          />
        </Collapse>
        <Collapse
          in={
            coApplicant?.housingSituation === HousingSituation.OwnsHouse &&
            !coApplicant?.livesWithMainApplicant
          }
        >
          <Stack gap="16px">
            <ButtonToggleGroup
              label="Har medlåntaker boliglån?"
              labelVariant="inputLabel"
              buttons={standardButtonOptions}
              onSelectedChange={(value) => {
                if (value !== coApplicant.hasMortgage) {
                  handleUpdate({
                    target: {
                      name: `${InputFieldsCoApplicant.hasMortgage}`,
                      value,
                    },
                  });
                }
              }}
              name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasMortgage}`}
              value={coApplicant?.hasMortgage}
            />
            <Collapse in={coApplicant?.hasMortgage}>
              <Stack gap="16px">
                <CustomNumericFormat
                  allowNegative={false}
                  value={coApplicant?.remainingMortgageAmount}
                  onValueChange={(value) => {
                    handleUpdate({
                      target: {
                        name: `${InputFieldsCoApplicant.remainingMortgageAmount}`,
                        value: value.floatValue,
                      },
                    });
                  }}
                  onBlur={() => {
                    dispatch(
                      handleNumberRangeInputValidation(
                        500000,
                        15000000,
                        coApplicant?.remainingMortgageAmount ?? 0,
                        `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.remainingMortgageAmount}`
                      )
                    );
                  }}
                  errorText="Oppgi beløp"
                  name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.remainingMortgageAmount}`}
                  label="Hvor mye gjenstår av medlåntakers boliglån?"
                  thousandSeparator=" "
                  customInput={TextInput}
                  type="text"
                  variant="outlined"
                  slotProps={{
                    htmlInput: {
                      maxLength: 8,
                      inputMode: "numeric",
                    },
                    input: {
                      sx: {
                        maxWidth: "150px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">kr</InputAdornment>
                      ),
                    },
                  }}
                />
                <CustomNumericFormat
                  allowNegative={false}
                  label="Hvor mye betaler medlåntaker i måneden på boliglånet?"
                  thousandSeparator=" "
                  customInput={TextInput}
                  type="text"
                  variant="outlined"
                  errorText="Oppgi beløp"
                  value={coApplicant?.paymentOnMortgageMonthly}
                  onValueChange={(value) => {
                    handleUpdate({
                      target: {
                        name: `${InputFieldsCoApplicant.paymentOnMortgageMonthly}`,
                        value: value.floatValue,
                      },
                    });
                  }}
                  onBlur={() => {
                    dispatch(
                      handleNumberRangeInputValidation(
                        1000,
                        100000,
                        coApplicant?.paymentOnMortgageMonthly ?? 0,
                        `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.paymentOnMortgageMonthly}`
                      )
                    );
                  }}
                  name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.paymentOnMortgageMonthly}`}
                  slotProps={{
                    htmlInput: {
                      maxLength: 7,
                      inputMode: "numeric",
                    },
                    input: {
                      sx: {
                        maxWidth: "150px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">kr</InputAdornment>
                      ),
                    },
                  }}
                />
              </Stack>
            </Collapse>
          </Stack>
        </Collapse>
      </Stack>
    </>
  );
};

export default CoApplicantHousing;
